import React from "react"
import { Script } from 'gatsby';
import { Helmet } from "react-helmet"
import { LWrap, MicroCmsImage } from "../../components/_index"
import formatDateJa from "../../utils/format-date-ja"
import periodText from "../../utils/period-text"
import MicroCmsContents from "./microcms_contents"

const FixedPageLayout = ({ data }: { data: any }) => {

  return (
    <>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function(){
    const sentenceAtag = document.querySelectorAll('.js_articleSentence a');
    for (let i = 0; i < sentenceAtag.length; i++) {
      const target = sentenceAtag[i].getAttribute('target')
      const icon_blank = document.createElement("i")
      icon_blank.className = 'icon-blank';
      const pdf_check = /\.pdf$/.test(sentenceAtag[i].getAttribute('href'))
      const icon_pdf = document.createElement("i")
      icon_pdf.className = 'icon-pdf';
      if(pdf_check){
        sentenceAtag[i].prepend(icon_pdf);
      }else if(target == "_blank"){
        sentenceAtag[i].appendChild(icon_blank);
      }
    }
})(document)
        `}} />
      {data.contents_group.map((item: any, index: number) => {
        const oddflag = index % 2 !== 0
        return (
          <section className={`l_sect02 ${oddflag ? 'u_bgWhite' : ''}`} key={index}>
            <LWrap>
              <MicroCmsContents data={item.contents} />
            </LWrap>
          </section>
        )
      })}
    </>
  )
}
export default FixedPageLayout;
